import * as Icons from '../../utils/icons';
import * as React from 'react';
import * as cn from 'classnames';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Button from '../Button';
import { MyTheme } from '../../types/types';
import { validateEmail } from '../../utils/form';

// import * as loaderSvg from '../../assets/loader.svg'

const RequestDemoText = {
    header: 'Request demo',
    text: 'Thank you for your interest. Please send us your details and we will be in touch with you shortly.'
};

type OwnProps = {
    open?: boolean;
    close?: () => void;
    lightMode?: boolean;
};

class RequestDemoForm extends React.PureComponent<OwnProps & WithSheet<StyleCreator<string, any>>> {
    state = {
        name: '',
        email: '',
        tel: '',
        organisation: '',
        error: false,
        errorMessage: '',
        loader: false
    };

    onChangeHandler = (e: any) => {
        e.preventDefault();
        this.setState({ [e.currentTarget.id]: e.currentTarget.value, error: false });
    };

    onSubmitHandler = () => {
        const { close } = this.props;
        if (validateEmail(this.state.email)) {
            this.setState({ loader: true });
            try {
                const url = 'https://formkeep.com/f/314e502732c8';
                const headers = {
                    accept: 'application/javascript',
                    'Content-Type': 'application/json'
                };
                const payload = {
                    name: this.state.name,
                    email: this.state.email,
                    tel: this.state.tel,
                    organisation: this.state.organisation
                };
                fetch(url, {
                    method: 'post',
                    headers,
                    body: JSON.stringify(payload)
                }).then(() => {
                    this.setState({ loader: false });
                    if (close) {
                        close();
                    }
                });
            } catch (error) {
                this.setState({
                    error: true,
                    loader: false,
                    errorMessage: 'There was a problem, please try again'
                });
            }
        } else {
            this.setState({ error: true, errorMessage: 'Please double check your email and try again.' });
        }
    };

    render() {
        const { classes, lightMode } = this.props;
        const { name, email, tel, organisation, error, errorMessage, loader } = this.state;
        return (
            <div className={cn(classes.container, lightMode ? classes.lightModeContainer : undefined)}>
                <div className={cn(classes.header, lightMode ? classes.lightMode : undefined)}>
                    <h1>{RequestDemoText.header}</h1>
                    <p>{RequestDemoText.text}</p>
                </div>
                <div className={classes.inputContainer}>
                    <input
                        type="text"
                        value={name}
                        id="name"
                        name="name"
                        placeholder="Name"
                        required
                        className={classes.input}
                        onChange={this.onChangeHandler}
                    />

                    <input
                        type="email"
                        value={email}
                        id="email"
                        name="email"
                        placeholder="Professional Email"
                        required
                        className={classes.input}
                        onChange={this.onChangeHandler}
                    />

                    <input
                        type="tel"
                        value={tel}
                        id="tel"
                        name="tel"
                        placeholder="Phone Number"
                        required
                        className={classes.input}
                        onChange={this.onChangeHandler}
                    />

                    <input
                        type="text"
                        value={organisation}
                        id="organisation"
                        name="organisation"
                        placeholder="Organisation"
                        required
                        className={classes.input}
                        onChange={this.onChangeHandler}
                    />
                </div>
                <div className={classes.buttonContainer}>
                    {error && (
                        <div className={classes.errorMessage}>
                            <span>{errorMessage}</span>
                        </div>
                    )}
                    {loader && Icons.loader()}
                    <Button
                        style={{ display: 'flex', justifyContent: 'center', padding: '5%' }}
                        primary
                        onClick={this.onSubmitHandler}
                        disabled={loader}
                    >
                        Request demo
                    </Button>
                </div>
            </div>
        );
    }
}

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center',
        position: 'relative',
        textAlign: 'center',
        alignItems: 'center',
        height: '100%'
    },
    header: {
        width: '100%',
        color: theme.palette.fog.primary,
        textAlign: 'left',
        marginLeft: '30px',
        marginTop: 5 * theme.basePadding
    },
    lightMode: {
        color: theme.palette.basic.white,
        padding: `0 ${theme.basePadding}px`
    },
    lightModeContainer: {
        border: `2px solid ${theme.palette.fog.outline}`,
        borderRadius: theme.borderRadius,
        backgroundColor: theme.palette.bigBlue.primary
    },
    errorMessage: {
        textAlign: 'center',
        color: theme.palette.medusa.primary
    },
    inputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    input: {
        maxWidth: '280px',
        width: '100%',
        color: theme.palette.basic.white,
        backgroundColor: theme.palette.bigBlue.canvasHighlight,
        border: 'none',
        padding: 2 * theme.basePadding,
        margin: theme.basePadding,
        '&::placeholder': {
            color: theme.palette.fog.primary
        }
    },
    buttonContainer: {
        margin: theme.basePadding,
        width: '100%'
    }
});

export default injectSheet(styles)(RequestDemoForm);
