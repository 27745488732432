import * as Modal from 'react-modal';
import * as React from 'react';

import injectSheet, { StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { MyTheme } from 'src/types/types';

type OwnProps = {
    children: React.ReactNode;
    open: boolean;
    close?: () => void;
};

class ModalComponent extends React.PureComponent<OwnProps & WithSheet<StyleCreator<string, any>>> {
    componentDidMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { children, classes, open, close } = this.props;
        return (
            <div style={{ position: 'absolute', zIndex: 9999 }}>
                <Modal
                    style={{
                        ...styles,
                        content: {
                            backgroundColor: '#151823',
                            border: `1px solid #46494c`,
                            borderRadius: '2px'
                        },
                        overlay: {
                            backgroundColor: 'rgba(0, 8, 16, 0.97)',
                            position: 'fixed',
                            zIndex: 9999
                        }
                    }}
                    shouldCloseOnEsc={true}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={close}
                    shouldReturnFocusAfterClose={false}
                    className={classes.content}
                    isOpen={open}
                    contentLabel="Modal"
                >
                    <span className={classes.close} onClick={close ? () => close() : () => null}>
                        Close
                    </span>
                    {children}
                </Modal>
            </div>
        );
    }
}

const styles = (theme: MyTheme): Record<string, any> => ({
    content: {
        maxWidth: '700px',
        height: '100vh',
        margin: '0 auto',
        outline: 'none',
        textAlign: 'right'
    },
    close: {
        color: theme.palette.basic.white,
        margin: theme.basePadding,
        cursor: 'pointer'
    },
    '@media (min-width: 800px)': {
        content: {
            height: '500px',
            margin: '10vh auto',
            padding: 2 * theme.basePadding
        }
    }
});

export default injectSheet(styles)(ModalComponent);
