import * as React from 'react';
import * as logo from '../../assets/LightIconColor.png';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { CONTACTS_SECTION_ID } from '../Footer/contacts';
import Link from 'gatsby-link';
import { MyTheme } from 'src/types/types';
import RequestDemo from '../RequestDemo';

type OwnProps = {
    opacity: number;
};

const DesktopNavigation = ({ opacity, classes }: WithSheet<StyleCreator<string, any>> & OwnProps) => {
    return (
        <nav role="navbar" className={classes.navBarWeb}>
            <div
                style={{
                    background: `rgba(34, 37, 46, ${opacity})`
                }}
                className={classes.navList}
            >
                <Link className={classes.navIcon} to="/">
                    <img style={{ opacity }} className={classes.logo} alt="Vortexa Ltd" src={logo} />
                </Link>
                <div className={classes.navPageMenu}>
                    <Link className={classes.nav} to="/">
                        Home
                    </Link>
                    <Link className={classes.nav} to="/insight">
                        Insights
                    </Link>
                    <Link className={classes.nav} to="/company">
                        Company
                    </Link>
                    <a className={classes.nav} href={`#${CONTACTS_SECTION_ID}`}>
                        Contact us
                    </a>
                </div>
                <div className={classes.requestDemo}>
                    <RequestDemo />
                </div>
            </div>
        </nav>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    navBarWeb: {
        display: 'flex',
        position: 'fixed',
        zIndex: 3,
        width: '100%',
        top: 0
    },
    navList: {
        width: '100%',
        padding: `0px ${theme.basePadding}px`,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        height: '70px'
    },
    navPageMenu: {
        minWidth: '15%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex'
    },
    nav: {
        textDecoration: 'none',
        margin: theme.basePadding,
        color: 'white'
    },
    navIcon: {
        display: 'flex',
        '@media (min-width: 870px)': {
            width: '260px'
        },
        marginLeft: 3 * theme.basePadding
    },
    logo: {
        width: '70px',
        height: '70px'
    }
});

export default injectSheet(styles)(DesktopNavigation);
