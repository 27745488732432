import * as React from 'react';
import * as requestDemo from '../../assets/RequestDemo.png';

import { Hidden, Visible } from 'react-grid-system';
import injectSheet, { StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Button from '../Button';
import ModalComponent from '../Modal';
import { MyTheme } from 'src/types/types';
import RequestDemoForm from '../Forms/requestDemo';

class RequestDemo extends React.PureComponent<WithSheet<StyleCreator<string, any>>> {
    state = {
        open: false
    };

    onModalToggle() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        const { open } = this.state;
        const { classes } = this.props;
        return (
            <span>
                <Hidden xs>
                    <div className={classes.buttonContainer}>
                        <div className={classes.buttonSection}>
                            <Button>
                                <a href="https://analytics.vortexa.com/">Login</a>
                            </Button>
                        </div>
                        <div className={classes.buttonSection}>
                            <Button onClick={() => this.onModalToggle()} primary>
                                Request a demo
                            </Button>
                        </div>
                    </div>
                </Hidden>
                <Visible xs>
                    <div onClick={() => this.onModalToggle()} style={{ height: '30px' }}>
                        <img src={requestDemo} style={{ height: '30px' }} />
                    </div>
                </Visible>
                <ModalComponent open={open} close={() => this.onModalToggle()}>
                    <RequestDemoForm close={() => this.onModalToggle()} />
                </ModalComponent>
            </span>
        );
    }
}

const styles = (theme: MyTheme) => ({
    buttonContainer: {
        display: 'flex'
    },
    buttonSection: {
        margin: `0 ${theme.basePadding}px`
    }
});

export default injectSheet(styles)(RequestDemo);
