import * as React from 'react';
import * as cn from 'classnames';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { MyTheme } from 'src/types/types';

type OwnProps = {
    text?: string;
    title?: string;
    subtitle?: string;
    opacity?: number;
    icon?: string;
};

class HeaderText extends React.Component<OwnProps & WithSheet<StyleCreator<string, any>>> {
    state = { currentScrollHeight: 0 };

    componentDidMount() {
        window.onscroll = () => {
            const newScrollHeight = Math.ceil(window.scrollY / 50) * 50;
            if (this.state.currentScrollHeight !== newScrollHeight) {
                this.setState({ currentScrollHeight: newScrollHeight });
            }
        };
    }
    render() {
        const { title, text, subtitle, classes, opacity, icon } = this.props;
        if (!title) {
            return null;
        }

        return (
            <div
                className={cn(classes.text, !icon || icon.length === 0 ? classes.centered : undefined)}
                style={{ opacity }}
            >
                <span>
                    <h1>{title}</h1>
                    <h3>{subtitle}</h3>
                    <p>{text}</p>
                </span>
            </div>
        );
    }
}

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    centered: {
        textAlign: 'center'
    },
    text: {
        fontWeight: 200,
        display: 'flex',
        flexDirection: 'column',
        color: theme.palette.basic.white,
        '& span': {
            alignItems: 'left',

            '& h1': {
                fontSize: theme.typography.sizes.h0,
                lineHeight: '1.5'
            },
            '& h3': {
                fontSize: theme.typography.sizes.h2
            },
            '& p': {
                marginTop: '16px',
            }
        }
    },
    requestDemo: {
        marginTop: `${theme.basePadding}px`
    },
    '@media (min-width: 600px) and (max-width: 900px)': {
        text: {
            '& span': {
                '& h1': {
                    fontSize: theme.typography.sizes.h0
                },
                '& h3': {
                    fontSize: theme.typography.sizes.h2
                },
                '& p': {
                    display: 'block',
                    fontSize: theme.typography.sizes.h4
                }
            }
        }
    },
    '@media (min-width: 900px) and (max-width: 1200px)': {
        text: {
            '& span': {
                '& h1': {
                    fontSize: theme.typography.sizes.h0
                },
                '& h3': {
                    fontSize: theme.typography.sizes.h2
                },
                '& p': {
                    display: 'block',
                    fontSize: theme.typography.sizes.h4
                }
            }
        }
    },
    '@media (min-width: 1200px)': {
        text: {
            '& span': {
                '& h1': {
                    fontSize: theme.typography.sizes.h0
                },
                '& h3': {
                    fontSize: theme.typography.sizes.h1
                },
                '& p': {
                    display: 'block',
                    fontSize: theme.typography.sizes.h3
                }
            }
        }
    },
    '@media (min-width: 1800px)': {
        text: {
            '& span': {
                '& h1': {
                    fontSize: theme.typography.sizes.h0
                },
                '& h3': {
                    fontSize: theme.typography.sizes.h1
                },
                '& p': {
                    display: 'block',
                    fontSize: theme.typography.sizes.h3
                }
            }
        }
    }
});

export default injectSheet(styles)(HeaderText);
