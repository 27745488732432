import * as React from 'react';
import * as icon from '../../assets/Light Full Color.png';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { Link } from 'gatsby';
import { MyTheme } from 'src/types/types';

const Corporate = ({ classes }: WithSheet<StyleCreator<string, any>>) => (
    <div>
        <img className={classes.icon} alt="Vortexa Ltd" src={icon} />
        <div className={classes.policies}>
            <span>© Vortexa Ltd.</span>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/cookie-policy">Cookie Policy</Link>
        </div>
    </div>
);

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    icon: {
        height: '100px',
        display: 'flex',
        margin: `${theme.cardContentPadding}px auto`
    },
    policies: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        '& > *': {
            padding: `${theme.basePadding}px`
        }
    }
});

export default injectSheet(styles)(Corporate);
