import * as React from 'react';
import * as cn from 'classnames';
import * as email from '../../assets/Vortexa_Icons_Email.png';
import * as linkedin from '../../assets/Vortexa_Icons_LinkedIn.png';
import * as phone from '../../assets/Vortexa_Icons_Phone.png';
import * as twitter from '../../assets/Vortexa_Icons_Twiiter.png';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { MyTheme } from 'src/types/types';

type ContactType = {
    twitter: string;
    linkedin: string;
    email: string;
    phone: string;
};
type OwnProps = {
    contacts: ContactType;
};

export const CONTACTS_SECTION_ID = 'contacts';

const Contacts = ({ contacts, classes }: OwnProps & WithSheet<StyleCreator<string, any>>) => (
    <div id={CONTACTS_SECTION_ID} className={cn(classes.contacts, classes.container)}>
        <div className={classes.entry}>
            <div>Contact us:</div>
            <div>
                <img alt="Phone icon" src={phone} />
                <a href={`tel:${contacts.phone}`}>{contacts.phone}</a>
            </div>
            <div>
                <img alt="Email icon" src={email} />
                <a href={`mailto:${contacts.email}`}>{contacts.email}</a>
            </div>
        </div>
        <div className={classes.entry}>
            <div>Follow us:</div>
            <div>
                <img alt="Twitter icon" src={twitter} />
                <a href={contacts.twitter} target="_blank">
                    @VortexaNews
                </a>
            </div>
            <div>
                <img alt="LinkedIn icon" src={linkedin} />
                <a href={contacts.linkedin} target="_blank">
                    LinkedIn
                </a>
            </div>
        </div>
    </div>
);

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    container: theme.content,
    contacts: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center',
        borderTop: `1px solid ${theme.palette.fog.primary}`,
        borderBottom: `1px solid ${theme.palette.fog.primary}`
    },
    entry: {
        width: '200px',
        margin: `${theme.cardContentPadding}px`,
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            margin: `${theme.basePadding}px 0px`
        }
    }
});

export default injectSheet(styles)(Contacts);
