export const resizeImage = (url: string, quality: number, width?: number, height?: number): string => {
    if (width && height) {
        return url.replace('.com/', `.com/resize=width:${width},height:${height}/quality=value:${quality}/`);
    }
    if (width && !height) {
        return url.replace('.com/', `.com/resize=width:${width}/quality=value:${quality}/`);
    }
    if (height && !width) {
        return url.replace('.com/', `.com/resize=height:${height}/quality=value:${quality}/`);
    }
    return url;
};
