import * as React from 'react';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { MyTheme } from 'src/types/types';

type LocationObject = {
    city: string;
    company: string;
    address: string;
    postcode: string;
};

type OwnProps = {
    locations: LocationObject[];
};

const Location = ({ locations, classes }: OwnProps & WithSheet<StyleCreator<string, any>>) => (
    <div className={classes.locations}>
        {locations.map((location: any, index: any) => (
            <div key={index} className={classes.entry}>
                <h1 className={classes.city}>{location.city}</h1>
                <p>{location.company}</p>
                <p>{location.address}</p>
                <p>{location.address2}</p>
                <p>{location.city}</p>
                <p>{location.postcode}</p>
            </div>
        ))}
    </div>
);

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    locations: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center'
    },
    entry: {
        width: '200px',
        margin: `${theme.cardContentPadding}px`
    },
    city: {
        fontFamily: theme.typography.families.primary
    }
});

export default injectSheet(styles)(Location);
