import * as React from 'react';

import { Hidden, Visible } from 'react-grid-system';

import Desktop from './desktop';
import Mobile from './mobile';

type OwnProps = {
    opacity: number;
};

export const NavigationBar = ({ opacity }: OwnProps) => (
    <div>
        <Visible xs>
            <Mobile />
        </Visible>
        <Hidden xs>
            <Desktop opacity={opacity} />
        </Hidden>
    </div>
);
