import * as React from 'react';
import * as blog from '../../assets/Blog.png';
import * as contact from '../../assets/Contact.png';
import * as login from '../../assets/Login.png';
import * as logo from '../../assets/LightIconColor.png';
import * as profile from '../../assets/Profile.png';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { CONTACTS_SECTION_ID } from '../Footer/contacts';
import Link from 'gatsby-link';
import { MyTheme } from 'src/types/types';
import RequestDemo from '../RequestDemo';

const MobileNavigation = ({ classes }: WithSheet<StyleCreator<string, any>>) => {
    const navTop = (
        <nav role="main" className={classes.navBarPhoneSub}>
            <a href="https://analytics.vortexa.com/">
                <img className={classes.icon} src={login} />
            </a>
        </nav>
    );

    const navBottom = (
        <nav role="main" className={classes.navBarPhone}>
            <div className={classes.navListPhone}>
                <div className={classes.iconContainer}>
                    <Link className={classes.navPhone} to="/">
                        <img className={classes.icon} alt="Vortexa Ltd" src={logo} />
                        <p className={classes.linkName}>Home</p>
                    </Link>
                </div>
                <div className={classes.iconContainer}>
                    <Link className={classes.navPhone} to="/company">
                        <img className={classes.icon} src={profile} />
                        <p className={classes.linkName}>Company</p>
                    </Link>
                </div>
                <div className={classes.iconContainer}>
                    <Link className={classes.navPhone} to="/insight">
                        <img className={classes.icon} src={blog} />
                        <p className={classes.linkName}>Insight</p>
                    </Link>
                </div>
                <div className={classes.iconContainer}>
                    <a className={classes.navPhone} href={`#${CONTACTS_SECTION_ID}`}>
                        <img className={classes.icon} src={contact} />
                        <p className={classes.linkName}>Contact</p>
                    </a>
                </div>
                <div className={classes.iconContainer}>
                    <RequestDemo />
                    <p className={classes.linkName}>Demo</p>
                </div>
            </div>
        </nav>
    );

    return (
        <div>
            {navBottom}
            {navTop}
        </div>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    navBarPhone: {
        display: 'flex',
        justifyContent: 'center',
        position: 'fixed',
        zIndex: 3,
        width: '100%',
        bottom: 0,
        background: theme.palette.basic.white,
        boxShadow: `0 -2px 2px -2px ${theme.palette.fog.outline}`
    },
    navBarPhoneSub: {
        display: 'flex',
        position: 'fixed',
        zIndex: 3,
        width: '100%',
        top: 0,
        background: theme.palette.basic.white,
        boxShadow: `0 2px 2px ${theme.palette.fog.outline}`,
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    navListPhone: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex'
    },
    navPhone: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    linkName: {
        textAlign: 'center'
    },
    iconContainer: {},
    icon: {
        display: 'flex',
        width: '30px'
    }
});

export default injectSheet(styles)(MobileNavigation);
