import * as React from 'react';
import * as classnames from 'classnames';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { MyTheme } from 'src/types/types';

type OwnProps = {
    primary?: true;
    type?: string;
    style?: CSSProperties;
    onClick?: () => void;
    children?: React.ReactNode;
    disabled?: boolean;
};

const Button = (props: OwnProps & WithSheet<StyleCreator<string, any>>) => {
    const { type, style, children, disabled, classes, primary, onClick } = props;
    return (
        <div style={style}>
            <button
                onClick={onClick}
                type={type}
                className={classnames(
                    primary ? classes.primary : classes.secondary,
                    classes.basic,
                    disabled ? classes.disabled : undefined
                )}
                disabled={disabled}
            >
                {children}
            </button>
        </div>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    basic: {
        height: '44px',
        fontSize: theme.typography.sizes.body,
        borderRadius: theme.borderRadius,
        width: '130px',
        cursor: 'pointer'
    },
    primary: {
        background: theme.palette.vortexa.primary,
        color: theme.palette.basic.white,
        border: 'none',
        '&:hover': {
            background: theme.palette.bigBlue.hover,
            boxShadow: `0 1px 2px ${theme.palette.fog.outline}`,
            transition: `box-shadow 0.1s ease-in-out`
        }
    },
    secondary: {
        borderColor: theme.palette.basic.black,
        background: theme.palette.basic.white,
        borderWidth: '1px',
        '&:hover': {
            boxShadow: `0 1px 2px ${theme.palette.fog.outline}`,
            transition: `box-shadow 0.1s ease-in-out`
        }
    },
    disabled: {
        cursor: 'not-allowed'
    }
});

export default injectSheet(styles)(Button);
