import * as React from 'react';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { Link } from 'gatsby';

type OwnProps = {
    icon: string;
    opacity: number;
};

class HeaderIcon extends React.PureComponent<OwnProps & WithSheet<StyleCreator<string, any>>> {
    render() {
        const { icon, classes, opacity } = this.props;

        return (
            <div className={classes.iconContainer}>
                <Link className={classes.navIcon} to="/">
                    <img style={{ opacity }} className={classes.icon} alt="Vortexa Ltd" src={icon} />
                </Link>
            </div>
        );
    }
}

const styles = (): Record<string, CSSProperties> => ({
    iconContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    icon: {
        display: 'flex',
        width: '100%'
    }
});

export default injectSheet(styles)(HeaderIcon);
