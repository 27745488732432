import * as React from 'react';
import * as cn from 'classnames';
import * as largeDivider from '../../assets/BG.jpg';
import * as smallDivider from '../../assets/BG (Mobile).png';

import { Hidden, Visible } from 'react-grid-system';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import Contacts from './contacts';
import Legal from './corporate';
import Location from './locations';
import { MyTheme } from 'src/types/types';

const contacts = {
    twitter: 'https://twitter.com/vortexanews',
    linkedin: 'https://www.linkedin.com/company/vortexa/',
    email: 'info@vortexa.com',
    phone: '+44 (203) 972-2212'
};

const locations = [
    {
        city: 'London',
        company: 'Vortexa Ltd.',
        address: 'Portsoken House',
        address2: '155 Minories',
        postcode: 'EC3N 1LJ'
    },
    {
        city: 'Singapore',
        company: 'Vortexa Asia Pte. Ltd.',
        address: '8 Cross Street #18-01',
        postcode: '048424'
    }
];

const FooterComponent = ({ classes }: WithSheet<StyleCreator<string, any>>) => (
    <div className={classes.footer}>
        <Hidden xs>
            <div className={classes.imageContainer}>
                <div className={classes.container}>
                    <img className={cn(classes.divider, classes.largeDivider)} alt="Vortexa Ltd" src={largeDivider} />
                </div>
            </div>
        </Hidden>
        <Visible xs>
            <img className={cn(classes.divider, classes.smallDivider)} alt="Vortexa Ltd" src={smallDivider} />
        </Visible>
        <div className={classes.content}>
            <Location locations={locations} />
            <Contacts contacts={contacts} />
            <Legal />
        </div>
    </div>
);

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    footer: {
        position: 'relative'
    },
    imageContainer: {
        backgroundColor: '#000810'
    },
    container: theme.content,
    divider: {
        width: '100%',
        height: 'auto'
    }
});

export const Footer = injectSheet(styles)(FooterComponent);
