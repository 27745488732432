import * as React from 'react';
import * as cn from 'classnames';

import { Col, Row } from 'react-grid-system';
import injectSheet, { StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import HeaderIcon from './HeaderIcon';
import HeaderText from './HeaderText';
import { MyTheme } from '../../types/types';
import { NavigationBar } from '../Navigation';
import { resizeImage } from '../../utils/images';

export type PageHeaderData = {
    title?: string;
    background: string;
    mobileBackground?: string;
    body?: string;
    icon?: string;
    subtitle?: string;
};

type OwnProps = {
    data: PageHeaderData;
    small?: boolean;
};

type OwnState = {
    headerScrollCompletion: number;
};

class PageHeader extends React.Component<OwnProps & WithSheet<StyleCreator<string, any>>, OwnState> {
    state = { headerScrollCompletion: 0 };
    header = React.createRef<any>(); // TODO: Check proper typing to get offsetHeight from HTMLElement

    componentDidMount() {
        window.onscroll = () => {
            const { current } = this.header;
            if (current && current.offsetHeight) {
                const relativeScroll = Math.min(window.pageYOffset / current.offsetHeight, 1.0);
                if (this.state.headerScrollCompletion !== relativeScroll) {
                    this.setState({ headerScrollCompletion: relativeScroll });
                }
            }
        };
    }

    componentWillUnmount() {
        window.onscroll = () => {
            return;
        };
    }

    render() {
        const { data, classes, small } = this.props;
        const { headerScrollCompletion } = this.state;

        const fadeOut = 1.0 - headerScrollCompletion;
        const icon = data.icon && <HeaderIcon icon={data.icon} opacity={fadeOut} />;

        return (
            <div style={{ position: 'relative' }}>
                <NavigationBar opacity={1 - fadeOut} />
                <div
                    ref={this.header}
                    role="banner"
                    className={cn(small ? classes.smallWrapper : classes.wrapper, classes.banner)}
                >
                    <div className={classes.textBlock}>
                        <div className={classes.gradient} />
                        <div className={classes.container}>
                            <Row justify="center">
                                {icon && (
                                    <Col xs={0} md={4}>
                                        {icon}
                                    </Col>
                                )}
                                <Col xs={12} md={8} style={{ opacity: fadeOut }}>
                                    <HeaderText
                                        title={data.title}
                                        text={data.body}
                                        subtitle={data.subtitle}
                                        icon={data.icon}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const styles = (theme: MyTheme): Record<string, any> => ({
    smallWrapper: {
        height: '30vh',
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: -1
    },
    wrapper: {
        height: '70vh',
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: -1
    },
    textBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    container: {
        width: '100%',
        margin: `${theme.cardContentPadding}px`
    },
    banner: (props: OwnProps) => ({
        background: `url('${props.data.mobileBackground || props.data.background}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    }),
    '@media(min-width: 600px)': {
        banner: (props: OwnProps) => ({
            background: `url('${resizeImage(props.data.background, 80, 2000, 1000)}')`,
            // background: `url('${props.data.background}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
        })
    },
    gradient: {
        position: 'fixed',
        width: '100%',
        top: 0,
        height: '100vh',
        backgroundImage: 'linear-gradient(to top, rgba(34,37,46,0), rgba(34,37,46,0.5))'
    }
});

export default injectSheet(styles)(PageHeader);
