import * as React from 'react';

import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import { MyTheme } from 'src/types/types';

const Spacer = ({ classes }: WithSheet<StyleCreator<string, any>>) => <div className={classes.spacer} />;

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    spacer: {
        height: `${theme.cardContentPadding}px`
    }
});

export default injectSheet(styles)(Spacer);
